import hillo from 'hillo'

export async function getNewSettingList () {
  return (await hillo.get('Restaurant.php?op=getAllSystemSettings', {
  })).content.map(it => {
    it.tagList = it.tagList ? it.tagList : 'basic'
    return it
  })
}

export async function getSystemVersion () {
  return (await hillo.get('MyVersion.php?op=version', {
  })).version
}

export async function updateNewSetting (items) {
  return (await hillo.post('Restaurant.php?op=updateSystemSettings', {
    systemSettings: JSON.stringify(items)
  })).content
}

export async function forceGetSystemSetting (item) {
  return (await hillo.post('Restaurant.php?op=forceGetSystemSetting', {
    systemSetting: JSON.stringify(item)
  })).content
}

export async function addSystemSettings (items) {
  return (await hillo.post('Restaurant.php?op=addSystemSettings', {
    systemSettings: JSON.stringify(items)
  })).content
}
