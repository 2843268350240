import store from '@/model/global/store'

export function formatPrice (price) {
  if (isNaN(parseFloat(price)) || (price === Infinity || price === -Infinity)) {
    return ' - '
  }
  const res = parseFloat(price).toFixed(2) === '-0.00' ? '0.00' : parseFloat(price).toFixed(2)
  if (store.state.currencySymbols === '$') {
    return store.state.currencySymbols + ' ' + res.replace(',', '.')
  } else {
    return res.replace('.', ',') + ' ' + store.state.currencySymbols
  }
}
