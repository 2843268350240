import hillo from 'hillo'

export async function loadPrinterGroup (filter) {
  return (await hillo.get('Printer.php', {
    op: 'showPrinterGroups',
    ...filter
  }))
    .content
    .map(a => {
      a.text = a.name
      a.value = a.name
      return a
    })
}
